"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _colors = require('../colors');
var _constants = require('../constants');
var _typography = require('../typography');
var _omit = require('lodash/omit'); var _omit2 = _interopRequireDefault(_omit);
const typography = _typography.buildTypographyVariant.call(void 0, "light");
const buildButtonVariant = (mode) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        ..._omit2.default.call(void 0, typography.bodyBold, "color"),
        textTransform: "none",
        padding: `${_constants.SPACING.call(void 0, 3)} ${_constants.SPACING.call(void 0, 6)}`,
        ":has(> .MuiButton-endIcon)": {
          padding: `${_constants.SPACING.call(void 0, 2.5)} ${_constants.SPACING.call(void 0, 6)}`
        }
      },
      endIcon: {
        marginLeft: "2px",
        "& > *:first-child": {
          fontSize: "1.25rem",
          // 20/16
          lineHeight: 1.5
          // 24/16
        }
      },
      sizeLarge: {
        padding: `${_constants.SPACING.call(void 0, 3)} ${_constants.SPACING.call(void 0, 6)}`,
        minWidth: _constants.SPACING.call(void 0, 37.5)
      },
      sizeMedium: {
        ..._omit2.default.call(void 0, typography.bodySmallBold, "color"),
        padding: `${_constants.SPACING.call(void 0, 3)} ${_constants.SPACING.call(void 0, 5)}`
      },
      // Icon buttons and other small stuff
      sizeSmall: {
        ..._omit2.default.call(void 0, typography.bodySmallBold, "color"),
        padding: _constants.SPACING.call(void 0, 2),
        minWidth: 0,
        borderRadius: _constants.SPACING.call(void 0, 1)
      },
      // Pills
      outlinedInfo: {
        padding: `${_constants.SPACING.call(void 0, 1)} ${_constants.SPACING.call(void 0, 3)} !important`,
        border: `1.5px solid ${_colors.colors[mode].border.border2}`,
        color: _colors.colors[mode].typography.typo3,
        backgroundColor: _colors.colors[mode].background.quartery,
        backdropFilter: "blur(30px)",
        borderRadius: `${_constants.SPACING.call(void 0, 25)} !important`,
        "&:hover": {
          backgroundColor: _colors.colors[mode].background.secondary,
          border: `1.5px solid ${_colors.colors[mode].border.border1}`,
          color: _colors.colors[mode].accent.primary
        },
        "&:active": {
          backgroundColor: _colors.colors[mode].background.secondary,
          border: `1.5px solid ${_colors.colors[mode].border.border1}`,
          color: _colors.colors[mode].accent.primary
        }
      },
      // Variants
      containedPrimary: {
        backgroundColor: _colors.colors[mode].accent.primary,
        boxShadow: _colors.colors[mode].dropShadow.dropShadow200,
        color: _colors.colors[mode].accent.accent100,
        "&:disabled": {
          color: _colors.colors[mode].accent.accent100,
          backgroundColor: _colors.colors[mode].accent.primary,
          opacity: 0.3
        },
        "&:hover": {
          boxShadow: _colors.colors[mode].dropShadow.dropShadow200,
          color: _colors.colors[mode].accent.accent100,
          backgroundColor: _colors.colors[mode].accent.accent600,
          opacity: 0.9
        }
      },
      containedSecondary: {
        backgroundColor: _colors.colors[mode].background.secondary,
        boxShadow: "none",
        color: _colors.colors[mode].typography.typo3,
        "&:disabled": {
          color: _colors.colors[mode].accent.accent100,
          backgroundColor: _colors.colors[mode].accent.primary,
          opacity: 0.3,
          boxShadow: "none"
        },
        "&:hover": {
          color: _colors.colors[mode].accent.accent100,
          backgroundColor: _colors.colors[mode].accent.accent600,
          opacity: 0.9,
          boxShadow: "none"
        }
      },
      outlinedPrimary: {
        border: `2px solid ${_colors.colors[mode].accent.accent400}`,
        color: _colors.colors[mode].accent.primary,
        "&:disabled": {
          border: `2px solid ${_colors.colors[mode].typography.typo5}`,
          color: _colors.colors[mode].typography.typo1,
          opacity: 0.5
        },
        "&:hover": {
          backgroundColor: _colors.colors[mode].accent.accent200,
          border: `2px solid ${_colors.colors[mode].accent.accent400}`,
          color: _colors.colors[mode].accent.accent600
        }
      },
      textPrimary: {
        padding: `${_constants.SPACING.call(void 0, 2)} !important`,
        color: _colors.colors[mode].accent.primary,
        "&:disabled": {
          color: _colors.colors[mode].typography.typo1,
          opacity: 0.5
        },
        "&:hover": {
          color: _colors.colors[mode].accent.accent600
        }
      },
      containedError: {
        backgroundColor: _colors.colors[mode].semantic.error.darker,
        color: _colors.colors[mode].accent.accent100,
        "&:disabled": {
          color: _colors.colors[mode].accent.accent100,
          backgroundColor: _colors.colors[mode].semantic.error.red800,
          opacity: 0.7
        },
        "&:hover": {
          backgroundColor: _colors.colors[mode].semantic.error.red800,
          color: _colors.colors[mode].accent.accent100
        }
      }
    }
  }
});


exports.buildButtonVariant = buildButtonVariant;
