"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildTableVariant = (mode) => ({
  MuiTableContainer: {
    styleOverrides: {
      root: {
        background: "none",
        "&.variant-portfolio .MuiTable-root": {
          borderSpacing: `0px ${_constants.SPACING.call(void 0, 1)} !important`,
          "& .MuiTableHead-root .MuiTableCell-root": {
            paddingLeft: _constants.SPACING.call(void 0, 6),
            "&:first-of-type": {
              paddingLeft: _constants.SPACING.call(void 0, 8)
            }
          }
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        transition: "background-color 0.2s ease-in-out",
        backgroundColor: `${_colors.colors[mode].background.secondary} !important`,
        "&:hover": {
          backgroundColor: `${_colors.colors[mode].background.tertiary} !important`
        }
      },
      head: {
        backgroundColor: `${_colors.colors[mode].background.quarteryNoAlpha} !important`,
        "&:hover": {
          backgroundColor: `${_colors.colors[mode].background.quarteryNoAlpha} !important`
        }
      }
    }
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-root": {
          borderBottom: `1px solid ${_colors.colors[mode].border.border1}`,
          paddingTop: _constants.SPACING.call(void 0, 2),
          paddingBottom: _constants.SPACING.call(void 0, 2)
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        borderBottom: "none",
        "&:first-of-type": {
          paddingLeft: `${_constants.SPACING.call(void 0, 6)}`
        },
        "&:last-of-type": {
          paddingRight: `${_constants.SPACING.call(void 0, 6)}`
        }
      },
      body: {
        "&:first-of-type": {
          borderTopLeftRadius: _constants.SPACING.call(void 0, 2),
          borderBottomLeftRadius: _constants.SPACING.call(void 0, 2)
        },
        "&:last-of-type": {
          borderTopRightRadius: _constants.SPACING.call(void 0, 2),
          borderBottomRightRadius: _constants.SPACING.call(void 0, 2)
        }
      }
    }
  },
  MuiTablePagination: {
    defaultProps: {
      component: "div",
      rowsPerPageOptions: [],
      labelDisplayedRows: () => ""
    },
    styleOverrides: {
      root: {
        overflow: "visible"
      },
      toolbar: {
        padding: `${_constants.SPACING.call(void 0, 6)} 0 0 0`
      },
      spacer: {
        display: "none"
      },
      displayedRows: {
        display: "none"
      }
    }
  }
});


exports.buildTableVariant = buildTableVariant;
