"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
var _material = require('@mui/material');
var _constants = require('../../theme/constants');
var _reactintl = require('react-intl');
var _button = require('../button');
var _icons = require('../../icons');
const StyledTokenPickerButton = _styledcomponents2.default.call(void 0, _button.Button).attrs({
  variant: "outlined"
})`
  ${(_ref) => {
  let {
    theme: {
      palette,
      spacing
    }
  } = _ref;
  return `
  display: flex;
  align-items: center;
  gap: ${spacing(2)};
  padding: ${spacing(2)};
  border-radius: ${spacing(15)};
  transition: all 300ms;
  transition-property: box-shadow, background-color;
  background-color: ${_theme.colors[palette.mode].background.secondary};
  border: 1px solid ${_theme.colors[palette.mode].border.border1};
  box-shadow: ${_theme.colors[palette.mode].dropShadow.dropShadow100};
  :disabled {
    box-shadow: none;
  }

  &:hover {
    background-color: ${_theme.colors[palette.mode].background.tertiary};
    border: 1px solid ${_theme.colors[palette.mode].border.border1};
    box-shadow: ${_theme.colors[palette.mode].dropShadow.dropShadow200};
  }
`;
}}
`;
const StyledEmptyTokenIcon = _styledcomponents2.default.div`
  ${(_ref2) => {
  let {
    $realSize,
    theme: {
      palette: {
        mode
      }
    }
  } = _ref2;
  return `
  width: ${$realSize};
  height: ${$realSize};
  background-color: ${_theme.colors[mode].background.primary};
  border-radius: 50%;
  `;
}};
`;
const StyledActiveLabel = _styledcomponents2.default.call(void 0, _material.Typography).attrs({
  variant: "bodySemibold",
  noWrap: true
})`
  ${(_ref3) => {
  let {
    theme: {
      palette
    },
    $isSelected
  } = _ref3;
  return !$isSelected && `color: ${_theme.colors[palette.mode].typography.typo5};`;
}}
`;
const StyledKeyboardArrowDownIcon = _styledcomponents2.default.call(void 0, _icons.KeyboardArrowDownIcon)`
  ${(_ref4) => {
  let {
    theme: {
      palette
    }
  } = _ref4;
  return `
  color: ${_theme.colors[palette.mode].typography.typo2}
  `;
}};
`;
const TokenPickerButton = (_ref5) => {
  let {
    token,
    isLoading,
    onClick,
    showAction = true,
    defaultText,
    disabled,
    tokenSize
  } = _ref5;
  return /* @__PURE__ */ _react2.default.createElement(StyledTokenPickerButton, {
    disabled,
    onClick
  }, _optionalChain([token, 'optionalAccess', _ => _.icon]) || /* @__PURE__ */ _react2.default.createElement(StyledEmptyTokenIcon, {
    $realSize: _constants.SPACING.call(void 0, tokenSize || 7)
  }), /* @__PURE__ */ _react2.default.createElement(StyledActiveLabel, {
    $isSelected: !!token && !disabled
  }, isLoading ? /* @__PURE__ */ _react2.default.createElement(_material.Skeleton, {
    variant: "text",
    animation: "wave",
    width: _constants.SPACING.call(void 0, 25)
  }) : token ? token.symbol : defaultText || /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
    id: "Kbpl3O",
    defaultMessage: [{
      "type": 0,
      "value": "Select"
    }]
  })), showAction && /* @__PURE__ */ _react2.default.createElement(StyledKeyboardArrowDownIcon, {
    fontSize: "small"
  }));
};


exports.TokenPickerButton = TokenPickerButton;
