"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function TrendUpIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 12 13",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M3.7507 7.7927C3.6557 7.7927 3.5607 7.75769 3.4857 7.68269C3.3407 7.53769 3.3407 7.2977 3.4857 7.1527L5.0857 5.5527C5.1657 5.4727 5.2707 5.4327 5.3857 5.4427C5.4957 5.4527 5.5957 5.5127 5.6607 5.6077L6.2057 6.4277L7.9807 4.6527C8.1257 4.5077 8.3657 4.5077 8.5107 4.6527C8.6557 4.7977 8.6557 5.0377 8.5107 5.18269L6.4107 7.28269C6.3307 7.36269 6.2257 7.4027 6.1107 7.3927C6.0007 7.3827 5.9007 7.3227 5.8357 7.2277L5.2907 6.40769L4.0157 7.68269C3.9407 7.75769 3.8457 7.7927 3.7507 7.7927Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.25 6.29297C8.045 6.29297 7.875 6.12297 7.875 5.91797V5.29297H7.25C7.045 5.29297 6.875 5.12297 6.875 4.91797C6.875 4.71297 7.045 4.54297 7.25 4.54297H8.25C8.455 4.54297 8.625 4.71297 8.625 4.91797V5.91797C8.625 6.12297 8.455 6.29297 8.25 6.29297Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M7.5 11.543H4.5C1.785 11.543 0.625 10.383 0.625 7.66797V4.66797C0.625 1.95297 1.785 0.792969 4.5 0.792969H7.5C10.215 0.792969 11.375 1.95297 11.375 4.66797V7.66797C11.375 10.383 10.215 11.543 7.5 11.543ZM4.5 1.54297C2.195 1.54297 1.375 2.36297 1.375 4.66797V7.66797C1.375 9.97297 2.195 10.793 4.5 10.793H7.5C9.805 10.793 10.625 9.97297 10.625 7.66797V4.66797C10.625 2.36297 9.805 1.54297 7.5 1.54297H4.5Z"
  }));
}


exports.default = TrendUpIcon;
