"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _colors = require('../colors');
var _constants = require('../constants');
const buildSelectVariant = (mode) => ({
  MuiListSubheader: {
    styleOverrides: {
      root: {
        backgroundColor: "transparent"
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: _colors.colors[mode].typography.typo2,
        "&.MuiSelect-iconStandard": {
          color: `${_colors.colors[mode].accentPrimary} !important`
        }
      },
      standard: {
        paddingTop: `0 !important`,
        paddingBottom: `${_constants.SPACING.call(void 0, 1)} !important`
      }
    }
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        paddingTop: _constants.SPACING.call(void 0, 2.5),
        paddingBottom: _constants.SPACING.call(void 0, 2.5),
        paddingLeft: _constants.SPACING.call(void 0, 3),
        "&.MuiSelect-MuiMenu": {
          top: _constants.SPACING.call(void 0, 2),
          boxShadow: _colors.colors[mode].dropShadow.dropShadow200,
          "& .MuiMenu-paper .MuiMenu-list": {
            padding: `${_constants.SPACING.call(void 0, 3)} !important`,
            gap: _constants.SPACING.call(void 0, 0.5),
            "& .MuiDivider-root": {
              margin: `${_constants.SPACING.call(void 0, 4)} ${_constants.SPACING.call(void 0, 0)}`
            },
            "& .MuiMenuItem-root": {
              padding: _constants.SPACING.call(void 0, 3),
              borderRadius: _constants.SPACING.call(void 0, 2),
              border: `1px solid ${_colors.colors[mode].border.border1}`,
              backgroundColor: _colors.colors[mode].background.secondary,
              "&:hover": {
                backgroundColor: _colors.colors[mode].background.emphasis
              }
            }
          },
          "& .Mui-selected": {
            padding: _constants.SPACING.call(void 0, 3),
            backgroundColor: _colors.colors[mode].background.emphasis
          }
        }
      },
      paper: {
        backgroundColor: _colors.colors[mode].background.modals,
        border: `1px solid ${_colors.colors[mode].border.border2}`
      }
    }
  }
});


exports.buildSelectVariant = buildSelectVariant;
