"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
const RainCoinsUrl = 'url("https://ipfs.io/ipfs/QmaMVMdDRoUJsYBJaPs6E9y7MkobGM2p64PBXwb1HTRgFr")';
const RainCoinsContainer = _styledcomponents2.default.div`
  height: ${(_ref) => {
  let {
    height = "150px"
  } = _ref;
  return height;
}};
  width: ${(_ref2) => {
  let {
    width = "150px"
  } = _ref2;
  return width;
}};
  background-image: ${RainCoinsUrl};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
var rain_coins_default = RainCoinsContainer;


exports.default = rain_coins_default;
